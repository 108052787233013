/* global d3, Utilities */

import * as d3 from 'd3';
import Utilities from "./utilities";
import {CardsManager} from "./gauge";
import {BAYESBOX_MODE} from "./constantsMapping";
import Network from "./network";
import Node from "./node";
import RightColumn from "./rightColumn";
import {LineChart} from "./lineChart";

var Histogram = {
    /*
     * Histogram data updating
     * @param {type} g group tag where histogram was added
     * @param {type} barsStyleCSS bars style ex.: "fill: white"
     * @param {type} theData list of data for histogram.
     *              STRUCTURE:[{range:{min,max}, value},{range:{min,max}, value},{range:{min,max}, value}]
     * @param {type} width histogram width
     * @param {type} height histogram height
     * @returns {undefined}
     */
    updateHistogram: function (g, barsStyleCSS, theData, width, height) {
        var yScale = d3.scaleLinear();

//    g.append("text")
//            .attr("transform", "rotate(-90)")
//            .attr("y", 6)
//            .attr("dy", "0.71em")
//            .attr("text-anchor", "end")
//            .text("Frequency");

        yScale.domain([0, d3.max(theData, function (d) {
                return d.value;
            })]);

        //DRAWING
        yScale.rangeRound([height, 0]);


        var xScale = d3.scaleLinear()
                .domain([
                    d3.min(theData, function (d) {
                        return d.range.min;
                    }),
                    d3.max(theData, function (d) {
                        return d.range.max;
                    })
                ])    // values between 0 and 100
                .range([0, width]);   // map these the the chart width = total width minus padding at both sides
        var bars = g.selectAll(".bar")
                .exit()
                .remove()
                .data(theData);

        var barsMargin = 2;
        bars
                .enter().append("rect")
                .attr("class", "bar")
                .attr("x", function (d) {
                    return xScale(d.range.min) + barsMargin;
                })
                .attr("y", function (d) {
                    return yScale(d.value);
                })
                .attr("width", function (d) {
                    return xScale(d.range.max) - xScale(d.range.min) - barsMargin;
                })
                .attr("height", function (d) {
                    return height - yScale(d.value);
                })
                .attr("style", barsStyleCSS);
        var ticks = 4;
        //axis
        g.select(".axis--x")
                .attr("transform", Utilities.getTransformString(0, height))
                .call(d3.axisBottom(xScale).ticks(ticks));
        g.select(".axis--y")
                .call(d3.axisLeft(yScale).ticks(ticks, "%"));
        //grid lines
        g.select(".grid--x")
                .attr("transform", Utilities.getTransformString(0, height))
                .call(this.make_x_gridlines(xScale, ticks).tickSize(-height).tickFormat(""))
                .moveToBack();// in background
        g.select(".grid--y")
                .call(this.make_y_gridlines(yScale, ticks).tickSize(-width).tickFormat(""))
                .moveToBack();// in background
    },
    updateErrorBarChart: function (g, barsStyleCSS, theData, width, height) {
        let meanArray = [];
        let stdDevArray = [];
        let min = 0;
        let max = 0;
        let indexArray = [];
        let dataArr = [];
        if (typeof theData.values !== "undefined") {
            meanArray = theData.values[0].value;
            stdDevArray = theData.values[1].value;
            min = Math.min( ...meanArray.map((num, idx) => {
                return num - stdDevArray[idx];
            }), meanArray[0] - stdDevArray[0]);
            max = Math.max( ...meanArray.map((num, idx) => {
                return num + stdDevArray[idx];
            }), meanArray[0] + stdDevArray[0]);
            indexArray = Array.from(Array(meanArray.length).keys());
            dataArr = indexArray.map((val, idx) => {
                return {
                    index: val,
                    mean: meanArray[idx],
                    stdDev: stdDevArray[idx]
                };
            });
        }

        let yScale = d3.scaleLinear().domain([min, max]).range([height, 0]);




        var xScale = d3.scaleLinear()
            .domain([0, dataArr.length - 1])    // values between 0 and 100
            .range([0, width]);   // map these the the chart width = total width minus padding at both sides
        g.selectAll(".bar").remove();
        g.selectAll(".histogramLine").remove();
        var bars = g.selectAll(".bar")
            .data(dataArr);

        var barsMargin = 1;
        const lineGenerator = d3.line()
            .x((d) => xScale(d.index))
            .y((d) => yScale(d.mean));
//             .attr("style", barsStyleCSS);
        bars.enter().append("path")
            .attr("fill", "none")
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .attr("class", "histogramLine")
            .attr("d", lineGenerator(dataArr));

        bars
            .enter().append("rect")
            .attr("class", "bar errorBar")
            .attr("x", function (d) {
                return xScale(d.index) + barsMargin;
            })
            .attr("y", function (d) {
                return yScale(d.mean + d.stdDev);
            })
            .attr("width", function (d) {
                return 1;
            })
            .attr("height", function (d) {
                return yScale(d.mean - d.stdDev) - yScale(d.mean + d.stdDev);
            })
        bars
            .enter().append("rect")
            .attr("class", "bar errorBar")
            .attr("x", function (d) {
                return xScale(d.index) + barsMargin - 2;
            })
            .attr("y", function (d) {
                return yScale(d.mean - d.stdDev);
            })
            .attr("width", function (d) {
                return 5;
            })
            .attr("height", function (d) {
                return 1;
            })
            .attr(`meanPlusStdDev`, (d) => { // could be confusing but it's because of d3 scale transition
                return d.mean - d.stdDev;
            })
        bars
            .enter().append("rect")
            .attr("class", "bar errorBar")
            .attr("x", function (d) {
                return xScale(d.index) + barsMargin - 2;
            })
            .attr("y", function (d) {
                return yScale(d.mean+ d.stdDev);
            })
            .attr("width", function (d) {
                return 5;
            })
            .attr("height", function (d) {
                return 1;
            })
            .attr(`meanMinusStdDev`, (d) => {
                return d.mean + d.stdDev;
            })
        bars
            .enter().append("rect")
            .attr("class", "bar errorBar errorDiamond")
            .attr("x", function (d) {
                return xScale(d.index) + barsMargin - 2;
            })
            .attr("y", function (d) {
                return yScale(d.mean) - 2;
            })
            .attr("width", function (d) {
                return 5;
            })
            .attr("height", function (d) {
                return 5;
            })
            .attr(`mean`, (d) => {
                return d.mean;
            })

        const ticks = 4;
//         //axis
        g.select(".axis--x")
            .attr("transform", Utilities.getTransformString(0, height))
            .call(d3.axisBottom(xScale).ticks(ticks));
        g.select(".axis--y")
            .call(d3.axisLeft(yScale).ticks(ticks));
        //grid lines
        g.select(".grid--x")
            .attr("transform", Utilities.getTransformString(0, height))
            .call(this.make_x_gridlines(xScale, ticks).tickSize(-height).tickFormat(""))
            .moveToBack();// in background
        g.select(".grid--y")
            .call(this.make_y_gridlines(yScale, ticks).tickSize(-width).tickFormat(""))
            .moveToBack();// in background
    },
    /**
     * Create histogram
     * @param {type} g - group tag where histogram will be add
     * @param {type} theData - list of data for histogram.
     *              STRUCTURE:[{range:{min,max}, value},{range:{min,max}, value},{range:{min,max}, value}]
     * @param {type} width - histogram width
     * @param {type} height - histogram height
     * //optional args
     * @param {type} barsStyleCSS[default=""] - bars style ex.: "fill: white"
     * @param {type} axisClassCSS[default=""] - axis class with style
     */
    drawHistogram: function (g, theData, width, height, axisClassCSS, barsStyleCSS) {
        barsStyleCSS = typeof barsStyleCSS !== 'undefined' ? barsStyleCSS : "";
        axisClassCSS = typeof axisClassCSS !== 'undefined' ? axisClassCSS : "";
        //axis
        g.append("g")
                .attr("class", "axis axis--x " + axisClassCSS);
        g.append("g")
                .attr("class", "axis axis--y " + axisClassCSS);
        //grid lines
        g.append("g")
                .attr("class", "grid grid--x");
        g.append("g")
                .attr("class", "grid grid--y");

        this.updateHistogram(g, barsStyleCSS, theData, width, height);

//    // UPDATE
//    bars.attr("x", function (d) {
//        return xScale(d.range.min)+barsMargin;
//    })
//            .attr("y", function (d) {
//                return yScale(d.value);
//            })
//            .attr("width", function (d){
//                return xScale(d.range.max) - xScale(d.range.min) - barsMargin;
//            })
//            .attr("height", function (d) {
//                return height - yScale(d.value);
//            });
//
//    // EXIT
//    bars.exit()
//            .remove();
    },
    drawErrorBarChart: function (g, theData, width, height, axisClassCSS, barsStyleCSS) {
        barsStyleCSS = typeof barsStyleCSS !== 'undefined' ? barsStyleCSS : "";
        axisClassCSS = typeof axisClassCSS !== 'undefined' ? axisClassCSS : "";
        //axis
        g.append("g")
            .attr("class", "axis axis--x " + axisClassCSS);
        g.append("g")
            .attr("class", "axis axis--y " + axisClassCSS);
        //grid lines
        g.append("g")
            .attr("class", "grid grid--x");
        g.append("g")
            .attr("class", "grid grid--y");
        this.updateErrorBarChart(g, barsStyleCSS, theData, width, height);

    },
    /**
     * @private
     * gridlines in x axis function
     * @param {type} x
     * @param {type} ticks
     * @returns {unresolved}
     */
    make_x_gridlines: function (x, ticks) {
        return d3.axisBottom(x).ticks(ticks);
    },
    /**
     * @private
     * gridlines in y axis function
     * @param {type} y
     * @param {type} ticks
     * @returns {unresolved}
     */
    make_y_gridlines: function (y, ticks) {
        return d3.axisLeft(y).ticks(ticks);
    },
    addNodeNameToCard_G: function (container, name, value) {
        var existTitle = container.select(".cardNodeTitle");
        if(existTitle.size() > 0 && existTitle.text() === name){
            return;
        }
        existTitle.remove();
//        var div = container.append("div")
//                .classed("cardNodeTitle card-body text-center", true);
        var valOptions = {showValue: false};
        if (typeof value !== 'undefined') {
            valOptions.showValue = true;
            valOptions.valueFunction = () => value;
//            div.append("h1")
//                    .classed("card-title", true)
//                    .text(value);
        }
        CardsManager.drawFooterCard(container, "cardNodeTitle", valOptions);
//        div.append("p")
//                .classed("card-text " + CardsManager.getNodeCaptionClass(), true)
//                .text(name);

    },
    createResponsiveErrorBarChart:function (containerSelector, theData, histogramWidth, nodeId, barsStyleCSS, axisClassCSS) {
        var container = d3.select(containerSelector);
        container.html("");

        container.classed("svg-container", true);
        container.classed("mycard-block", false);
        container.style("padding-bottom", null);

        var svg = container.append("div")
            .append("svg")
            .attr("width", histogramWidth)
            .attr("height", "150px");

        var margin = {top: 20, right: 20, bottom: 20, left: 40};


        var g = svg.append("g")
            .attr("transform", Utilities.getTransformString(margin.left, margin.top));
        var bounds = container.node().getBoundingClientRect(),
            width = bounds.width - margin.left - margin.right,
            height = bounds.height - margin.top - margin.bottom;

        if (bounds.width === 0 || bounds.height === 0) {
            var displayInfo = RightColumn.Accordion.displayHiddenCard(container.node(), nodeId);
            bounds = container.node().getBoundingClientRect();
            width = bounds.width - margin.left - margin.right;
            height = bounds.height - margin.top - margin.bottom;
            RightColumn.Accordion.retractCardStates(displayInfo);
        }
        svg.attr("viewBox", "0 0 " + bounds.width + " " + bounds.height)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .classed("svg-content", true)// true meaning - add class
            .attr("width", null)
            .attr("height", null);

        Histogram.drawErrorBarChart(g, theData, width, height, axisClassCSS, barsStyleCSS);

        //tmp padding set for computations
        container.style("padding-bottom", "100%");
        bounds = svg.node().getBoundingClientRect();
        var containerSize = container.node().getBoundingClientRect();
        if (bounds.width === 0 || bounds.height === 0) {
            var displayInfo = RightColumn.Accordion.displayHiddenCard(container.node(), nodeId);
            bounds = svg.node().getBoundingClientRect();
            containerSize = container.node().getBoundingClientRect();
            RightColumn.Accordion.retractCardStates(displayInfo);
        }

        let paddingBottomRatio = 100 * (bounds.height + margin.bottom) / containerSize.height;
        container.style("padding-bottom", paddingBottomRatio + "%");

        if(BAYESBOX_MODE.isDashboard()){
            let n = d3.select(containerSelector).data()[0];
            this.addNodeNameToCard_G(d3.select(containerSelector.parentNode), n.getCurrentTitle());
        }
    },
    /*
     * Create histogram place in right column card
     * @param {type} containerSelector - CSS selector where histogram will create
     * @param {type} theData - list of data for histogram.
     *              STRUCTURE:[{range:{min,max}, value},{range:{min,max}, value},{range:{min,max}, value}]
     * @param {type} histogramWidth - histogram width
     * @param {type} id - node id. Important for accordion in right column where cards are hidden
     * //optional args
     * @param {type} barsStyleCSS[default=""] - bars style ex.: "fill: white"
     * @param {type} axisClassCSS[default=""] - axis class with style
     */
    createResponsiveHistogram: function (containerSelector, theData, histogramWidth, nodeId, barsStyleCSS, axisClassCSS) {
        var container = d3.select(containerSelector);
        container.html("");

        container.classed("svg-container", true);
        container.classed("mycard-block", false);
        container.style("padding-bottom", null);

        var svg = container.append("div")
                .on('mousedown', function (d) {
                    if (d3.event.detail > 1) {
                        d3.event.preventDefault();
                        d3.event.stopPropagation();
                        var nodeObject = d3.select(Network.getSVG_CSS_SELECTOR()).select("#" + nodeId);
                        Node.Evidence.InputEvidenceModal.showEv(nodeObject, d3.event);
                    }
                })
                .style('cursor', 'pointer')
                .append("svg")
                .attr("width", histogramWidth)
                .attr("height", "150px");

        var margin = {top: 20, right: 20, bottom: 20, left: 40};


        var g = svg.append("g")
                .attr("transform", Utilities.getTransformString(margin.left, margin.top));
        var bounds = container.node().getBoundingClientRect(),
                width = bounds.width - margin.left - margin.right,
                height = bounds.height - margin.top - margin.bottom;

        if (bounds.width === 0 || bounds.height === 0) {
            var displayInfo = RightColumn.Accordion.displayHiddenCard(container.node(), nodeId);
            bounds = container.node().getBoundingClientRect();
            width = bounds.width - margin.left - margin.right;
            height = bounds.height - margin.top - margin.bottom;
            RightColumn.Accordion.retractCardStates(displayInfo);
        }
        svg.attr("viewBox", "0 0 " + bounds.width + " " + bounds.height)
                .attr("preserveAspectRatio", "xMinYMin meet")
                .classed("svg-content", true)// true meaning - add class
                .attr("width", null)
                .attr("height", null);

        Histogram.drawHistogram(g, theData, width, height, axisClassCSS, barsStyleCSS);

        //tmp padding set for computations
        container.style("padding-bottom", "100%");
        bounds = svg.node().getBoundingClientRect();
        var containerSize = container.node().getBoundingClientRect();
        if (bounds.width === 0 || bounds.height === 0) {
            var displayInfo = RightColumn.Accordion.displayHiddenCard(container.node(), nodeId);
            bounds = svg.node().getBoundingClientRect();
            containerSize = container.node().getBoundingClientRect();
            RightColumn.Accordion.retractCardStates(displayInfo);
        }

        let paddingButtomRatio = 100 * (bounds.height + margin.bottom) / containerSize.height;
        container.style("padding-bottom", paddingButtomRatio + "%");

        if(BAYESBOX_MODE.isDashboard()){
            let n = d3.select(containerSelector).data()[0];
            this.addNodeNameToCard_G(d3.select(containerSelector.parentNode), n.getCurrentTitle());
        }
    }
};

export default Histogram;
